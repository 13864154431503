import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import axios from 'axios';

dayjs.locale('da');

// 1. Define route components.
// These can be imported from other files
var apiUrl = 'https://www.addanmark.dk/wp-json/';
var apikey = '5d41402abc4b2a76b9719d911017c592';

var SingleNewsTemplate = `
<div class="news">

  <br />

  <loading-spinner :pageLoaded="pageLoaded"></loading-spinner>

  <div class ="featured-image" v-if="news.featured_media">
    <img :src="featuredMedia">
  </div>

  <div class ="headline">
    <h3 v-html="title"></h3>
  </div>

  <div class ="date">
    <small>{{date}}</small>
  </div>

  <div class="content" v-html="content"></div>

</div>
`;

var SingleNews = Vue.component('SingleNews', {
  data: function() {
    return {
      news: '',
      title: '',
      date: '',
      featuredMedia: null,
      content: '',
      pageLoaded: false,
    };
  },

  created: function() {
    var self = this;
    axios
      .get(apiUrl + 'wp/v2/news/?slug=' + this.$route.params.newsid + '&_embed', {
        headers: {
          apikey: apikey,
        },
      })
      .then(function(response) {
        self.pageLoaded = true;
        self.news = response.data[0];
        self.formatDate(response.data[0].date);
        if (response.data[0].title.rendered.length) {
          self.title = response.data[0].title.rendered;
        }

        if (response.data[0].content.rendered.length) {
          self.content = response.data[0].content.rendered.replace(/http:/g, '');
        }

        if (response.data[0]._embedded) {
          self.featuredMedia =
            response.data[0]._embedded['wp:featuredmedia'][
              '0'
            ].media_details.sizes.large.source_url;
        }
      })
      .catch(function(error) {
        console.log('Error: ', error);
      });
  },

  methods: {
    formatDate: function(date) {
      this.date = dayjs(date).format('dddd D MMMM YYYY - HH:mm');
    },

    /**
     * Track google analytics pageview
     */
  },
  template: SingleNewsTemplate,
});

Vue.component('loading-spinner', {
  props: ['pageLoaded'],

  data: function() {
    return {};
  },
  template:
    "<div class='spinner' v-if='!pageLoaded'><div class='sk-fading-circle'><div class='sk-circle1 sk-circle'></div><div class='sk-circle2 sk-circle'></div><div class='sk-circle3 sk-circle'></div><div class='sk-circle4 sk-circle'></div><div class='sk-circle5 sk-circle'></div><div class='sk-circle6 sk-circle'></div><div class='sk-circle7 sk-circle'></div><div class='sk-circle8 sk-circle'></div><div class='sk-circle9 sk-circle'></div><div class='sk-circle10 sk-circle'></div><div class='sk-circle11 sk-circle'></div><div class='sk-circle12 sk-circle'></div></div></div>",
});

export default SingleNews;
